
export default {
  data() {
    return {
      relatedPosts: null,
    };
  },
  mounted() {
    if (
      this.$refs.singlePost &&
      this.$refs.singlePost.pageData &&
      this.$refs.singlePost.pageData.relatedPosts
    ) {
      this.relatedPosts = this.$refs.singlePost.pageData.relatedPosts;
    }

    const links = document.getElementsByClassName("relatedPostLink");
    if (links && links.length) {
      Array.from(links).forEach((link) => {
        link.removeAttribute("target");
      });
    }

    // add class on first 2 images
    const images = Array.from(document.querySelectorAll("p img"));
    if (images && images.length) {
      images[0].classList.add("img1");
      if (images[1]) {
        images[1].classList.add("img2");
      }
    }
  },
  methods: {},
};
