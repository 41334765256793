import Vue from "vue";

Vue.mixin({
  data() {
    return {
      defaultLanguage: "",
      containerSpace: null,
    };
  },
  mounted() {
    if (
      this.$application &&
      this.$application.i18n &&
      this.$application.i18n.defaultLocale
    ) {
      this.defaultLanguage = this.$application.i18n.defaultLocale;
    }

    this.$nextTick(function () {
      this.onResizeGetContainerMargin();
    });
    window.addEventListener("resize", this.onResizeGetContainerMargin, {
      passive: true,
    });
  },
  computed: {
    $homepageLink() {
      if (this.$i18n.locale === this.defaultLanguage) {
        return "/";
      } else {
        return `/${this.$i18n.locale}`;
      }
    },
  },
  methods: {
    onResizeGetContainerMargin() {
      const el = document.getElementById("element");
      if (el) {
        const style = window.getComputedStyle(el);

        const viewPortOffset = el.getBoundingClientRect();
        const space = viewPortOffset.left;
        this.containerSpace = space + parseInt(style.paddingLeft) + "px";
      }
    },
    $formatDate(dateStr) {
      if (!dateStr) return;
      const locale = this.$i18n.locale;
      var date = new Date(dateStr);

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      date = `${day}.${month}.${year}.`;

      return date;
    },
    $scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 200);
    },
  },
});
