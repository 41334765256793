
import * as vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      searchOpen: false,
      menuOpen: false,
      homepage: false,
      activeNavElement: null,
    };
  },
  watch: {
    $route() {
      this.checkIfHomepage();

      this.closeSearch();
      this.closeMenu();
    },
  },
  mounted() {
    this.checkIfHomepage();
  },
  methods: {
    openSearch() {
      this.searchOpen = true;

      if (document.getElementById("searchInput")) {
        setTimeout(() => {
          document.getElementById("searchInput").focus();
        }, 300);
      }
    },
    closeSearch() {
      this.searchOpen = false;
    },
    toggleMenu(e) {
      const el = e.currentTarget;
      if (el) {
        this.menuOpen = el.classList.contains("menuOpen");
      }

      const element = document.documentElement;
      if (element) {
        if (this.menuOpen) {
          element.classList.add("overflow-hidden");
        } else {
          element.classList.remove("overflow-hidden");
        }
      }
    },
    closeMenu() {
      this.menuOpen = false;
      const openClass = document.getElementsByClassName("menuOpen");
      if (openClass && openClass[0]) {
        openClass[0].classList.remove("menuOpen");
      }

      const element = document.documentElement;
      if (element) {
        element.classList.remove("overflow-hidden");
      }
    },
    checkIfHomepage() {
      const currentLocation = window.location.pathname;
      if (
        currentLocation == "/" ||
        currentLocation == "/naslovnica" ||
        currentLocation == "/pocetna" ||
        currentLocation == "/hr" ||
        currentLocation == "/hr/" ||
        currentLocation == "/hr/naslovnica" ||
        currentLocation == "/hr/pocetna" ||
        currentLocation == "/en" ||
        currentLocation == "/en/" ||
        currentLocation == "/homepage" ||
        currentLocation == "/home" ||
        currentLocation == "/en/home" ||
        currentLocation == "/en/homepage"
      ) {
        this.homepage = true;
      } else {
        this.homepage = false;
      }
    },
  },
};
