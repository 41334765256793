
export default {
  props: {
    paginationState: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    pageNumbers: {
      type: [String, Array, Number],
      required: true,
    },
  },
  watch: {
    "options.page"(val) {
      this.$router.push({ query: { page: this.options.page } });
    },
  },
  computed: {
    computedPageNumbers() {
      if (this.pageNumbers && this.pageNumbers.length) {
        let pageNumbers = this.pageNumbers;
        if (
          this.options &&
          this.options.page &&
          this.pageNumbers.length &&
          this.pageNumbers[this.pageNumbers.length - 1] === this.options.page
        ) {
          if (this.options.page - 2 !== 0) {
            pageNumbers = [
              this.options.page - 2,
              this.options.page - 1,
              this.options.page,
            ];
          } else {
            pageNumbers = [this.options.page - 1, this.options.page];
          }
        }
        return pageNumbers;
      }
    },
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.page) {
      this.options.page = parseInt(this.$route.query.page);
    }
  },
};
