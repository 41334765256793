
export default {
  data() {
    return {
      options: [
        {
          name: "icon",
          label: "Ikona",
          type: "image",
          size: "20x20",
        },
        {
          name: "title",
          label: "Naslov",
          type: "text",
        },

        {
          name: "description",
          label: "Opis",
          type: "tipTap",
        },
      ],
    };
  },
};
