import { render, staticRenderFns } from "./PostList.vue?vue&type=template&id=094df914"
import script from "./PostList.vue?vue&type=script&lang=js"
export * from "./PostList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiPaginationComponent: require('/home/node/app/components/ui/paginationComponent.vue').default,ControllerPostList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js').default})
