
export default {
  data() {
    return {
      isMounted: false,
    };
  },
  computed: {
    pageNumbers() {
      if (this.isMounted) {
        return this.$refs.postList.pageNumbers;
      }
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
