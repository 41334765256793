
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    link: {
      type: [String, Object],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    blueBtn() {
      let colorClass = "bg-primary-192A3D md:hover:bg-secondary-3A4F66";
      return colorClass;
    },

    disabledClasses() {
      let colorClass = "cursor-not-allowed bg-secondary-E1E8ED";
      return colorClass;
    },
  },
};
